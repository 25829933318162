import { useEffect, useRef, useState } from "react";
import {Link, useNavigate } from "react-router-dom";
import {Badge, Button, Card, Col, Form, ListGroup} from "react-bootstrap";
import { CustomRow } from "../commons";
import { AxiosApi } from "../../axiosApi";
import { Status, Ticket, User, NewInteraction } from "../interfaces/interfaces";


function CreateInteraction({ticket}:{ticket:Ticket}) {
  const navigate = useNavigate();
  const emptyStatusList:Array<Status> = []
  const [statusList, setStatusList] = useState<Array<Status>>(emptyStatusList)
  const [isLoading, setLoading] = useState(true);
  const axiosApi = AxiosApi();
  const URL = window.location.pathname;
  const fetchStatusList = async () => {
    const response = await axiosApi.get(process.env.REACT_APP_API_STATUS ?? "")
    const fetchedStatusList:Array<Status> = response.data
    setStatusList(fetchedStatusList)
    console.debug(response)
    setLoading(false)
  };
  useEffect(() => {
    fetchStatusList()
  }, []);
  const content = useRef<HTMLTextAreaElement>(null)
  const newStatus = useRef<HTMLSelectElement>(null)
  if (isLoading) return <></>
  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newInteractionData:NewInteraction = {content: content?.current?.value||"",
                                            ticket: ticket.id,
                                            newStatus: newStatus?.current?.value||""}
    let INTERACTION_URL:string = process.env.REACT_APP_API_INTERACTION ?? ''
    try {
      console.debug(newInteractionData)
      const response = await axiosApi.post(INTERACTION_URL,newInteractionData);
      console.debug(response.data)
      if (response.status === 201){
        navigate(0)
      }
    } catch (err) {
        console.log(err)
    }
  };
  return (
    <CustomRow className="mt-3">
      <Col lg={8}>
        <Card>
          <Card.Body>
            <Form onSubmit={onSubmitHandler}>  
              <Form.Group className="mb-3">
                <Form.Label id="label-set-comment" className="ps-1">
                  <b>
                  Registrar Comentário
                  </b>
                  </Form.Label>
                <Form.Control aria-labelledby="label-set-comment" name="content" ref={content} as="textarea" className="text-muted" required>
                </Form.Control>
                <Form.Label className="ps-1 pb-0 mt-3">
                  <b>
                  Mudar Status:
                  </b>
                  </Form.Label>
                <Form.Select defaultValue={ticket.status.id} ref={newStatus} aria-label="Selecione o status do chamado">
                    {statusList.map((status) => (
                      <option key={status.id} value={status.id}>{status.alias}{" (atual)"?status.id===ticket.status.id:""}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
              <Button type="submit">Enviar</Button>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </CustomRow>
  );
}

export { CreateInteraction };
