import { useIsAuthenticated, useAuthHeader} from 'react-auth-kit';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NewTicketButton from './new-ticket-button';
import TicketDropDown from "./ticket-dropdown";
import SolveTicketsDropDown from './solve-tickets-dropdown';
import { DropdownStatus } from '../interfaces/interfaces';
import { useEffect, useState } from 'react';
import {AxiosApi} from "../../axiosApi"
import AllOptionsTicketsDropDown from './all-options-tickets-dropdown';



function AppNavBar() {
  let app_name:string = process.env.REACT_APP_APP_NAME ?? '';
  let tickets_api_url: string = process.env.REACT_APP_API_TICKET ?? "";
  let tickets_url: string = tickets_api_url+"?req=1";
  let dropdownTitle: string = "Chamados abertos por mim";
  const isAuthenticated = useIsAuthenticated();


  if (!isAuthenticated()){
    return(<></>);
  }
  

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">{app_name}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Início</Nav.Link>
            <NewTicketButton></NewTicketButton>
            <AllOptionsTicketsDropDown/>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}


export default AppNavBar;