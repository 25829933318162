import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthHeader } from "react-auth-kit";
import {Button, Col, Container, Form, FormGroup, FormLabel, Row} from "react-bootstrap";
import { PageTitle } from "../commons";
import {AxiosApi} from "../../axiosApi"

function NewTicket(this: any, props: any) {
  const authHeader =  useAuthHeader();
  const axiosApi = AxiosApi();
  const navigate = useNavigate();
  interface Subject{id: string,title: string}
  interface FormData {title:string, subject:string, description:string}
  const formData: FormData = {title: "", subject: "0", description: ""}
  const [allSubjects, setAllSubjects] = useState<Subject[]>([]);
  let subjects_url: string = process.env.REACT_APP_API_SUBJECT ?? "";
  let ticket_url: string = process.env.REACT_APP_API_TICKET ?? "";
  const fetchAllSubjects = async () => {
    const { data } = await axiosApi.get(subjects_url);
    const allSubjects = data;
    setAllSubjects(allSubjects);
  };

  useEffect(() => {
    fetchAllSubjects();
  }, []);

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      formData.title = title?.current?.value||""
      formData.subject = subject?.current?.value||""
      formData.description = description?.current?.value||""
      try {
        const response = await axiosApi.post(ticket_url,formData);
        console.debug(response)
        if (response.status = 201){
          const ticketId = response.data.id
          navigate("/ticket/"+ticketId);
        }
      } catch (err) {
          console.log(err)
      }
    };
    const title = useRef<HTMLInputElement>(null)
    const subject = useRef<HTMLSelectElement>(null)
    const description = useRef<HTMLTextAreaElement>(null)
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs lg="8">
          <PageTitle>Abrir Chamado</PageTitle>
          <Form onSubmit={onSubmitHandler}>  
            <Form.Group className="mb-3">
              <Form.Label>Título</Form.Label>
              <Form.Control name="title" ref={title} maxLength={127} type="text" placeholder="Resumo do Chamado" required={true}/>
              <Form.Text className="text-muted">
                Escreva de forma resumida sobre a demanda ou incidente.
              </Form.Text>
            </Form.Group>
            <FormGroup className="mb-3">
              <FormLabel>Assunto</FormLabel>
              <Form.Select name="subject" ref={subject} required={true}>
                {allSubjects.map((subject)=>(<option key={subject.id} value={subject.id}>{subject.title}</option>))}
              </Form.Select>
            </FormGroup>
            <Form.Group className="mb-3">
              <Form.Label>Descrição</Form.Label>
              <Form.Control name="description" ref={description} as="textarea" className="text-muted" required={true}>
                
              </Form.Control>
              <Form.Text className="text-muted">
                Informe os detalhes (Problema ou requisição, Sala, Urgência, etc.)
              </Form.Text>
            </Form.Group>
            <Button type="submit">Enviar</Button>
          </Form>
        </Col>
      </Row>
    </Container>

  );
}

export { NewTicket };
