import React from "react";
import logo from "./logo.svg";
import "./App.css";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
import { Login } from "./components/login/login";
import { Home } from "./components/home";
import { RequireAuth, useIsAuthenticated } from "react-auth-kit";
import { NewTicket } from "./components/ticket/new";
import AppNavBar from "./components/navbar/app-nav-bar";
import { SingleTicket } from "./components/ticket/single-ticket";
import { TicketList } from "./components/ticket/ticket-list";
import { EditProfile } from "./components/profile/edit";

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
`;

function App() {
  const isAuthenticated = useIsAuthenticated();
  return (
    <AppContainer>
      {isAuthenticated() ? (
          <AppNavBar></AppNavBar>
      ) : (
        ''
      )}
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth loginPath="/login">
              <Home />
            </RequireAuth>
          }
        ></Route>

      <Route
          path="/ticket"
          element={
            <RequireAuth loginPath="/login">
              <TicketList />
            </RequireAuth>
          }
        />
      <Route
          path="/ticket/novo"
          element={
            <RequireAuth loginPath="/login">
              <NewTicket />
            </RequireAuth>
          }
        ></Route>
      <Route
          path="/ticket/:ticketId"
          element={
            <RequireAuth loginPath="/login">
              <SingleTicket />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/profile/edit"
          element={
            <RequireAuth loginPath="/login">
              <EditProfile />
            </RequireAuth>
          }
        ></Route>       
        
        <Route path="/login" element={<Login />}></Route>
      </Routes>
    </AppContainer>
  );
}

export default App;
