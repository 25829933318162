import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {Card, Col, Container, Form, FormGroup, FormLabel, Row} from "react-bootstrap";
import { CustomRow } from "../commons";
import { ContactInformation, ContactInformationForInteraction, Interaction } from "../interfaces/interfaces";

function SingleInteraction(props: {interaction: Interaction}) {
  console.debug(props.interaction)
  let contactInformation:ContactInformationForInteraction = {id:"",
                                                            document: "",
                                                            name: "",
                                                            email: "",
                                                            phone: ""}
  let interaction:Interaction = {ticket:"", content:"", user:{id:"", defaultContactInformation:contactInformation}}
  interaction = props.interaction

  return (
    <CustomRow className="mt-2">
      <Col lg={8}>
        <Card>
          <Card.Header>
            <b>
              {interaction?.user?.defaultContactInformation.name+" escreveu:"}
            </b>
          </Card.Header>
          <Card.Body>
            <Card.Text className="ms-1" id={"interaction-content-"+interaction.id}>
              {interaction.content}
            </Card.Text>
          </Card.Body>
          {interaction.newStatus
          ?
            <Card.Footer>
              {"Status modificado para "}
              <b>
               {interaction?.newStatus?.alias}
              </b>
            </Card.Footer>
          :
          ""
          }
          <Card.Footer>
              {"Feito em: "+interaction.createdAt}
          </Card.Footer>
        </Card>
      </Col>
    </CustomRow>
  );
}

export default SingleInteraction;
