import React, { useState, useEffect } from "react";
import { useAuthHeader, useSignOut } from "react-auth-kit";
import { NavDropdown } from "react-bootstrap";
import {AxiosApi} from "../../axiosApi"
import TicketDropDown from "./ticket-dropdown";
import { DropdownStatus } from "../interfaces/interfaces";

function SolveTicketsDropDown({allStatus}:{allStatus:DropdownStatus[]}) {
  interface Status{
    id: number,
    alias: string
  }
  const axiosApi = AxiosApi();

  let tickets_url: string = "/ticket"
  
  return (
    <>
      <TicketDropDown title="Chamados sob minha responsabilidade" baseURL={tickets_url+"?res=1"} allStatus={allStatus}/>
      <TicketDropDown title="Resolver Chamados" baseURL={tickets_url+"?toSolve=1"} allStatus={allStatus}/>
    </>
  );
}

export default SolveTicketsDropDown;