import React, { useState, useEffect } from "react";
import { useAuthHeader, useSignOut } from "react-auth-kit";
import { NavDropdown } from "react-bootstrap";
import {AxiosApi} from "../../axiosApi"
import {DropdownStatus} from "../interfaces/interfaces"


function TicketDropDown({title, baseURL, allStatus}:{title:string, baseURL:string, allStatus:DropdownStatus[]}) {
  
  return (
    <NavDropdown title={title}>
        {allStatus.map((status) => (
          <NavDropdown.Item key={status.id} href={baseURL+"&status="+status.id}>{status.alias}</NavDropdown.Item>
          ))}
        <NavDropdown.Item key={null}  href={baseURL}>Todos os Status</NavDropdown.Item>
    </NavDropdown>
  );
}

export default TicketDropDown;