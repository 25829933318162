import { useEffect, useState } from "react";
import { AxiosApi } from "../../axiosApi";
import { TicketForBatch } from "../interfaces/interfaces";
import { Col, Row, Table } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";

function TicketList(this: any, props: any) {
    const axiosApi = AxiosApi();
    const ticket_url = process.env.REACT_APP_API_TICKET??"";
    const [tickets, setTickets] = useState<TicketForBatch[]>([]);
    const [params] = useSearchParams();

    

    const fetchTicketList = async () => {
        const { data } = await axiosApi.get(ticket_url, {params});
        setTickets(data);
        console.debug(params);
      };
    
      useEffect(() => {
        fetchTicketList();
      }, []);

    if (tickets.length === 0){
      return (
        <h4>Não há tickets que atendam à pesquisa.</h4>
      )
    }
    return (
      <Row className="justify-content-md-center">
        <Col xs lg="8">
          <h4>Lista de Chamados:</h4>
          {}
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Link</th>
                <th>Título</th>
                <th>Status</th>
                <th>Aberto por</th>
                <th>Aberto em</th>
              </tr>
            </thead>
            <tbody>
            {tickets.map((t) => (
              <tr key={t.id}>
                <td><Link to={"/ticket/"+t.id}>{t.id}</Link></td>
                <td>{t.title}</td>
                <td>{t.status.alias}</td>
                <td>{t.requestingUser.name}</td>
                <td>{t.createdAt.toString()}</td>
              </tr>
            ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    );

}


export {TicketList}