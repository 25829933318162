// First we need to import axios.js
import axios from 'axios';
import { useAuthHeader, useSignOut } from 'react-auth-kit';

export function AxiosApi (){
    const authHeader = useAuthHeader(); 
    const signOut = useSignOut();
    const instance = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        });
    instance.defaults.headers.common['Authorization'] = authHeader();
    instance.interceptors.response.use(function (response) {
        return response;
      }, function (error) {
        if (error.response.status == 403){
            console.log(error.response)
            signOut()
        }
      });
        
    return instance;
}
