import { Button } from "baseui/button";
import { Input } from "baseui/input";
import styled from "styled-components";
import {
  HeadingXXLarge,
  HeadingXLarge,
  HeadingLarge,
  HeadingMedium,
  HeadingSmall,
  HeadingXSmall,
} from "baseui/typography";
import {
  Container,
  ErrorText,
  InnerContainer,
  InputWrapper,
  StyledInput,
} from "../commons";

import { useAuthHeader, useSignIn } from "react-auth-kit";
import { useFormik } from "formik";
import axios, { AxiosError } from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

function Login(props: any) {
  const [error, setError] = useState("");
  const signIn = useSignIn();
  const navigate = useNavigate();
  const authHeader =  useAuthHeader();
 

  const onSubmit = async (values: any) => {
    setError("");

    let auth_url = process.env.REACT_APP_API_AUTHENTICATION_URL ?? '';

    try {
      const response = await axios.post(
        auth_url,
        {
          usuario:values.username,
          senha:values.password
        }
      );
      type Data = {
        nome: String;
        id: String;
      }
      type User = {
        data: Data;
      };

      let data:Data = {nome:"", id:""};
      let emptyUser:User = {data};
      let decodedJWT:User = jwtDecode(response.data.access_token)??emptyUser;
      console.log(decodedJWT.data.nome);

      signIn({
        token: response.data.access_token,
        expiresIn: 3600,
        tokenType: "Bearer",
        authState: { name: decodedJWT.data.nome, userId: decodedJWT.data.id },

      });

      //axios Configuration
      axios.defaults.baseURL = process.env.REACT_APP_API_URL;
      axios.defaults.headers.common['Authorization'] = authHeader();
      // axios.interceptors.request.use(function (config) {
        
      //   return config;
      // }, function (error) {
      //   // Do something with request error
      //   return Promise.reject(error);
      // });
      
      navigate("/");
    } catch (err) {
      if (err && err instanceof AxiosError)
        setError(err.response?.data.message);
      else if (err && err instanceof Error) setError(err.message);

      console.log("Error: ", err);
    }
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit,
  });

  return (
    <Container>
      <InnerContainer>
        <form onSubmit={formik.handleSubmit}>
          <HeadingXXLarge>Sistema de Chamados NCE</HeadingXXLarge>
          <ErrorText>{error}</ErrorText>
          <InputWrapper>
            <StyledInput
              name="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              placeholder="Identificação do Usuário"
              clearOnEscape
              size="large"
              type="text"
            />
          </InputWrapper>
          <InputWrapper>
            <StyledInput
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              placeholder="Senha"
              clearOnEscape
              size="large"
              type="password"
            />
          </InputWrapper>
          <InputWrapper>
            <Button type="submit" size="large" kind="primary" isLoading={formik.isSubmitting}>
              Login
            </Button>
          </InputWrapper>
        </form>
      </InnerContainer>
    </Container>
  );
}

export { Login };
