import { useIsAuthenticated } from 'react-auth-kit';
import Nav from 'react-bootstrap/Nav';
import { Navigate, useNavigate } from 'react-router-dom';

function NewTicketButton() {
  let app_name:string = process.env.REACT_APP_APP_NAME ?? '';
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const goToNewTicketPage = () => {
    navigate("/ticket/novo")
  }; 

  if (!isAuthenticated()){
    return(<></>);
  }
  return (
    <Nav.Link onClick={goToNewTicketPage}>Abrir Chamado</Nav.Link>
  );
}

export default NewTicketButton;