import { useState, useEffect } from "react";
import {AxiosApi} from "../../axiosApi"
import TicketDropDown from "./ticket-dropdown";
import { DropdownStatus } from "../interfaces/interfaces";
import SolveTicketsDropDown from "./solve-tickets-dropdown";

function AllOptionsTicketsDropDown() {
  const axiosApi = AxiosApi();

  const [allStatus, setAllStatus] = useState<DropdownStatus[]>([]);
  let status_url: string = process.env.REACT_APP_API_STATUS ?? "";

  const fetchAllStatus = async () => {
    const { data } = await axiosApi.get(status_url
    );
    setAllStatus(data);
    console.log(allStatus);
  };


  useEffect(() => {
    fetchAllStatus();
  }, []);



  let tickets_url: string = "/ticket";
  let myTicketsDropdownTitle: string = "Chamados abertos por mim";
  let myTicketsDropdownURL: string = tickets_url+"?req=1";

  
  return (
    <>
      <TicketDropDown title={myTicketsDropdownTitle} baseURL={myTicketsDropdownURL} allStatus={allStatus}/>
      <SolveTicketsDropDown allStatus={allStatus}/>
    </>
  );
}

export default AllOptionsTicketsDropDown;