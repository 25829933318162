import axios from "axios";
import { Button } from "baseui/button";
import { HeadingXLarge, HeadingXXLarge } from "baseui/typography";
import { useAuthUser, useSignOut } from "react-auth-kit";
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Container } from "../commons";

function Home() {
  const signOut = useSignOut();
  const navigate = useNavigate();
  const authUser = useAuthUser();

  const logout = () => {
    signOut();
    navigate("/login");
  };

  function newTicket() {
    navigate("/ticket/novo");
  }

  function editProfile(){
    navigate("/user/:id");
  }

  return (
    <Container role="main">
          <HeadingXXLarge color="secondary300">Sistema de Suporte NCE</HeadingXXLarge>
          <HeadingXLarge color="secondary500">olá, {authUser()!.name}</HeadingXLarge>
          <Button kind="secondary" className="mb-1" onClick={newTicket}>
            Abrir Chamado (Dúvida ou Incidente)
          </Button>
          {/* <Button kind="secondary" className="mb-1" onClick={editProfile}>
            Editar Meus Dados (Email, etc.)
          </Button> */}

          <Button kind="secondary" onClick={logout}>
            Sair do Sistema
          </Button>
    </Container>
  );
}

export { Home };
