import { useEffect, useRef, useState } from "react";
import {Link} from "react-router-dom";
import {Card, Col, Container} from "react-bootstrap";
import { CustomRow } from "../commons";
import { AxiosApi } from "../../axiosApi";
import { Status, Ticket, User, Interaction, ContactInformation } from "../interfaces/interfaces";
import { CreateInteraction } from "./new-interaction";
import SingleInteraction from "./single-interaction";

const SingleTicket = () => {
  const emptyUser:User = {id:""}
  const emptyContactInformation:ContactInformation = {id:"",
                                  document: "",
                                  name: "",
                                  email: "",
                                  phone: "",
                                  user: emptyUser,
                                  registrationReference: ""}
  const emptyStatus:Status = {id:"", alias:""}
  const emptyTicket: Ticket = {id:"",
                                title:"",
                                status:emptyStatus,
                                subject: "0",
                                description: "",
                                requestingUser:emptyContactInformation, 
                                responsibleUser: emptyUser,
                                interactions: [],
                                createdAt: new Date()}
  const [ticket, setTicket] = useState<Ticket>(emptyTicket)
  const [isLoading, setLoading] = useState(true);
  const axiosApi = AxiosApi();
  const URL = window.location.pathname;
  const fetchTicket = async () => {
    const response = await axiosApi.get(URL);
    console.debug(response)
    const fetchedTicket: Ticket = emptyTicket
    fetchedTicket.title = response.data.title
    fetchedTicket.description = response.data.description
    fetchedTicket.id = response.data.id;
    fetchedTicket.requestingUser = response.data.requestingUser
    fetchedTicket.responsibleUser = response.data.responsibleUser
    fetchedTicket.status = response.data.status
    fetchedTicket.createdAt = response.data.createdAt
    fetchedTicket.interactions = response.data.interactions
    console.debug(fetchedTicket.id)
    setTicket(fetchedTicket)
    setLoading(false)
  };
  useEffect(() => {
    fetchTicket()
  }, []);
  if (isLoading) return <></>
  return (
    <main>
      <CustomRow>
        <Col lg={8}>
          <Card>
            <Card.Header><Link style={{color: "blue"}} className="me-1"to={"/ticket/"+ticket.id}>Chamado #{ticket.id}</Link>({ticket.status.alias})</Card.Header>
            <Card.Body>
              <Card.Title id="ticket-title">{ticket.title}</Card.Title>
              <Card.Text id="ticket-description">
                {ticket.description}
              </Card.Text>
            </Card.Body>
            <Card.Footer>{"Aberto em: "+ticket.createdAt+
                                                " por "+ticket.requestingUser.name+
                                                " ("+ticket.requestingUser.id+")"}
            </Card.Footer>
          </Card>
        </Col>
      </CustomRow>
        {ticket.interactions.map(value => {
          return <SingleInteraction key={value.id} interaction={value}/>
        })}
      <CreateInteraction ticket={ticket}/>
    </main>
  );
}
export { SingleTicket };
