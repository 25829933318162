import axios from "axios";
import { Button } from "baseui/button";
import { HeadingXLarge, HeadingXXLarge } from "baseui/typography";
import { useAuthUser, useSignOut } from "react-auth-kit";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Container, CustomRow } from "../commons";

function EditProfile() {
  const navigate = useNavigate();
  const authUser = useAuthUser();


  function newTicket() {
    navigate("/ticket/novo");
  }

  return (
    <main>
    <CustomRow>
      <Col lg={8}>
        <Card>
          <Card.Header>Perfil: {}
            {/* <Link style={{color: "blue"}} className="me-1"to={"/ticket/"+ticket.id}>Chamado #{ticket.id}</Link> */}
            {/* ({ticket.status.alias}) */}
            </Card.Header>
          <Card.Body>
            <Card.Text id="ticket-description">
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </CustomRow>
  </main>
  );
}

export { EditProfile };
